@import '~@studyportals/styles-abstracts/abstracts';

.WidgetContainer {
	display: flex;
	max-width: 450px;
	justify-content: space-between;

	.WidgetCheckbox {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		cursor: pointer;
		@include TextStyle(Note);

		background-color: $GreyLL;
		color: $BrandBlue;
		border-radius: 15px;
		padding: 0.3rem 0.3rem 0.3rem 0.5rem;

		.WidgetGreen {
			color: $Green;
		}

		.WidgetLabel {
			margin: auto 0;
		}

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		input:checked ~ .Checkmark:after {
			display: block;
		}

		.Checkmark {
			margin: auto 0.25rem;
			height: 0.75rem;
			width: 0.75rem;
			background-color: $White;
			border: 1px solid $BrandBlue;
			border-radius: 20%;
			display: flex;
			justify-content: center;
		}

		.Selected {
			background-color: $BrandBlue;
		}

		.Checkmark:after {
			content: '';
			position: absolute;
			display: none;
			width: 4px;
			height: 8px;
			border: solid $White;
			border-width: 0 1px 1px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
